<template>
  <!-- Start Page Title Area -->
  <div class="page-title-area">
    <div class="container">
      <div class="page-title-content">
        <h2>Privacy policy & data processing</h2>
        <ul>
          <li>
            <router-link to="/" class="nav-link">
              <i class="bx bx-home"></i>
              Home
            </router-link>
          </li>
          <li class="active">Policy</li>
        </ul>
      </div>
    </div>
  </div>
  <!-- End Page Title Area -->





  <section class="standard-text-page-area">
    <div class="container">
      <div class="terms-conditions">

        <div class="conditions-content">

          <h2>Privacy policy of Digistore24 services</h2>
          <p>As Digistore acts as provider to sell our products, consult their <a href="https://www.digistore24.com/info/privacy/1?lang=en" style="text-decoration: underline">Privacy policy</a></p>


          <h2>Using this website</h2>

          <p>Last Updated: 05.05.2024.</p>

          <p>This Privacy Policy
            (“Policy”) explains the information collection, use, and sharing practices of
            Bits&amp;Bytes (“we,” “us,” and “our”).</p>

          <p>Unless otherwise stated, this
            Policy describes and governs the information collection, use, and sharing
            practices of Bits&amp;Bytes with respect to your use of our website
            (https://bitsandbytes.academy) and the services (“Services”) we provide and/or host
            on our servers.</p>

          <p>Before you use or submit
            any information through or in connection with the Services, please carefully
            review this Privacy Policy. By using any part of the Services, you understand
            that your information will be collected, used, and disclosed as outlined in
            this Privacy Policy.</p>

          <p>If you do not agree to this
            privacy policy, please do not use our Services.</p>

          <h3>Our Principles</h3>

          <p>Bits&amp;Bytes has designed
            this policy to be consistent with the following principles:</p>

          <ul>
            <li>Privacy policies should be
              human readable and easy to find.</li>
            <li>Data collection, storage, and
              processing should be simplified as much as possible to enhance security,
              ensure consistency, and make the practices easy for users to understand.</li>
            <li>Data practices should meet the
              reasonable expectations of users.</li>
          </ul>

          <h3>Information We Collect</h3>

          <p>We collect information in
            multiple ways, including when you provide information directly to us; when we
            passively collect information from you, such as from your browser or device;
            and from third parties.</p>

          <h3>Information You Provide Directly to Us</h3>

          <p>We will collect any information
            you provide to us. We may collect information from you in a variety of ways,
            such as when you: (a) create an online account, (b) make a purchase, (c)
            contact us or provide feedback, (d) subscribe to our newsletter. This
            information may include but is not limited to your name, email address, phone
            number, mailing address, payment information and your geographic location.</p>

          <h3>Information that Is Automatically Collected</h3>

          <p>Device/Usage Information</p>

          <p>We may automatically
            collect certain information about the computer or devices (including mobile
            devices or tablets) you use to access the Services. As described further below,
            we may collect and analyze (a) device information such as IP addresses,
            location information (by country and city), unique device identifiers, IMEI and
            TCP/IP address, browser types, browser language, operating system, mobile
            device carrier information, and (b) information related to the ways in which
            you interact with the Services, such as referring and exit web pages and URLs,
            platform type, the number of clicks, domain names, landing pages, pages and
            content viewed and the order of those pages, statistical information about the
            use of the Services, the amount of time spent on particular pages, the date and
            time you used the Services, the frequency of your use of the Services, error
            logs, and other similar information. As described further below, we may use
            third-party analytics providers and technologies, including cookies and similar
            tools, to assist in collecting this information.</p>

          <h3>Cookies and Other Tracking Technologies</h3>

          <p>We also collect data about
            your use of the Services through the use of Internet server logs and online
            tracking technologies, like cookies and/or tracking pixels. A web server log is
            a file where website activity is stored. A cookie is a small text file that is
            placed on your computer when you visit a website, that enables us to: (a)
            recognize your computer; (b) store your preferences and settings; (c)
            understand the web pages of the Services you have visited and the referral
            sites that have led you to our Services; (d) enhance your user experience by
            delivering content&nbsp; specific to your inferred interests; (e) perform
            searches and analytics; and (f) assist with security administrative functions.
            Tracking pixels (sometimes referred to as web beacons or clear GIFs) are tiny
            electronic tags with a unique identifier embedded in websites, online ads
            and/or email, and that are designed to provide usage information like ad
            impressions or clicks, measure popularity of the Services and associated
            advertising, and to access user cookies. We may also use tracking technologies
            in our license buttons and/or icons that you can embed on other sites/services
            to track the website addresses where they are embedded, gauge user interaction
            with them, and determine the number of unique viewers of them. If you receive
            email from us (such as the CC newsletter, campaign updates, or other ongoing
            email communications from CC), we may use certain analytics tools, such as
            clear GIFs, to capture data such as whether you open our message, click on any
            links or banners our email contains, or otherwise interact with what we send.
            This data allows us to gauge the effectiveness of our communications and
            marketing campaigns.&nbsp; As we adopt additional technologies, we may also
            gather additional information through other methods.</p>

          <p>Please note that you can
            change your settings to notify you when a cookie is being set or updated, or to
            block cookies altogether. Please consult the “Help” section of your browser for
            more information. Please note that by blocking any or all cookies, you may not
            have access to certain features or offerings of the Services.</p>

          <p>For more information about
            how we use cookies, please read our Cookie Policy.</p>

<!--          <h3>Information from Third Parties</h3>-->

<!--          <p>the extent permitted by-->
<!--            law, we may also collect information from third parties, including public-->
<!--            sources, social media platforms, and marketing and market research firms.-->
<!--            Depending on the source, this information collected from third parties could-->
<!--            include name, contact information, demographic information, information about-->
<!--            an individual’s employer, information to verify identity or trustworthiness,-->
<!--            and information for other fraud or safety protection purposes.</p>-->

          <h3>How We Use Your Information</h3>

          <p>We may use the information we
            collect from and about you to:</p>

          <ul>
            <li>Fulfill the purposes for which
              you provided it.</li>
            <li>Provide and improve the
              Services, including to develop new features or services, take steps to secure
              the Services, and for technical and customer support.</li>
            <li>Fundraise, accept donations, or
              process transactions.</li>
            <li>Send you information about your
              interaction or transactions with us, account alerts, or other
              communications, such as newsletters to which you have subscribed.</li>
            <li>Process and respond to your
              inquiries or to request your feedback.</li>
            <li>Conduct analytics, research,
              and reporting, including to synthesize and derive insights from your use
              of our Services.</li>
            <li>Comply with the law and protect
              the safety, rights, property, or security of Bits&amp;Bytes, the Services,
              our users, and the general public; and</li>
            <li>Enforce our Terms of Use,
              including to investigate potential violations thereof.</li>
          </ul>

          <p>Please note that we may
            combine information that we collect from you and about you (including
            automatically collected information) with information we obtain about you from
            our affiliates and/or non-affiliated third parties, and use such combined
            information in accordance with this Privacy Policy.</p>

          <p>We may aggregate and/or
            de-identify information collected through the Services. We may use
            de-identified and/or aggregated data for any purpose, including without
            limitation for research and marketing purposes.</p>

          <h3>When We Disclose Your Information</h3>

          <p>We may disclose and/or
            share your information under the following circumstances:</p>


          <h3>Payment Provider (Digistore24).</h3>

          <p>We have fully or partially outsourced our sales and payment processing to Digistore24. When making a payment through Digistore24, personal data such as first name, last name, email address, billing address, or payment details are collected for the purpose of processing the order. The processing of your data by Digistore24 is based on Article 6(1)(a) GDPR (consent) and Article 6(1)(b) GDPR (processing for the performance of a contract). You have the option to withdraw your consent to data processing at any time. A withdrawal does not affect the validity of past data processing activities.</p>

          <p>The data is initially stored until the limitation period expires due to the conclusion of a contract (e.g., purchasing a product). In accordance with Article 6(1)(c) GDPR, personal data that must be stored due to commercial and tax law obligations (such as billing address and purchase amount) will also be retained beyond these periods in accordance with the respective deadlines.</p>

          <p> We have also outsourced our affiliate program (affiliate marketing) to Digistore24, through which advertising cost reimbursements can be earned by placing advertisements and links to corresponding landing pages. In this context, Digistore24 uses cookies to track the mediation of orders through the affiliate. Among other things, Digistore24 can thus identify that an affiliate link on a specific website was clicked and subsequently a purchase was made. The storage of “Digistore24 cookies” is based on Article 6(1)(f) GDPR. The website operator has a legitimate interest in this, as the promotion of a product via an affiliate is only possible through this cookie. Further information on the handling of personal data by Digistore24 can be found in <a href="https://www.digistore24.com/page/privacy/1/en" target="_blank">Digistore24's Privacy Agreement for Buyers</a>.</p>


          <h3>Service Providers.</h3>

          <p>We may disclose your
            information with third parties who perform services on our behalf, including
            without limitation, event management, marketing, customer support, data
            storage, data analysis and processing, and legal services.</p>

          <h3>Legal Compliance and Protection of Creative Commons
            and Others.</h3>

          <p>We may disclose your
            information if required to do so by law or on a good faith belief that such
            disclosure is permitted by this Privacy Policy or reasonably necessary or
            appropriate for any of the following reasons: (a) to comply with legal process;
            (b) to enforce or apply our Terms of Use and this Privacy Policy, or other
            contracts with you, including investigation of potential violations thereof;
            (c) enforce our Charter including the Code of Conduct and policies contained
            and incorporated therein, (d) to respond to your requests for customer service;
            and/or (e) to protect the rights, property, or personal safety of Bits&amp;Bytes,
            our agents and affiliates, our users, and the public. This includes exchanging
            information with other companies and organizations for fraud protection, and
            spam/malware prevention, and similar purposes.</p>

          <h3>Business Transfers.</h3>

          <p>As we continue to develop
            our business, we may engage in certain business transactions, such as the
            transfer or sale of our assets. In such transactions, (including in
            contemplation of such transactions, e.g., due diligence) your information may
            be disclosed. If any of our assets are sold or transferred to a third party,
            customer information (including your email address) would likely be one of the
            transferred business assets.</p>

          <h3>Affiliated Companies.</h3>

          <p>We may disclose your
            information with current or future affiliated companies.</p>

          <h3>Consent.</h3>

          <p>We may disclose your
            information to any third parties based on your consent to do so.</p>

          <h3>Aggregate/De-identified Information.</h3>

          <p>We may disclose
            de-identified and/or aggregated data for any purpose to third parties,
            including advertisers, promotional partners, and/or others.</p>

          <h3>Legal Basis for Processing Personal Data</h3>

          <p>The laws in some
            jurisdictions require companies to tell you about the legal ground they rely on
            to use or disclose information that can be directly linked to or used to
            identify you. To the extent those laws apply, our legal grounds for processing such
            information are as follows:</p>

          <h3>To Honor Our Contractual Commitments to You.</h3>

          <p>Much of our processing of information
            is to meet our contractual obligations to provide services to our users.</p>

          <h3>Legitimate Interests.</h3>

          <p>In many cases, we handle
            information on the ground that it furthers our legitimate interests in ways
            that are not overridden by the interests or fundamental rights and freedoms of
            the affected individuals, these include:</p>

          <ul>
            <li>Customer
              service</li>
            <li>Marketing,
              advertising, and fundraising</li>
            <li>Protecting our users,
              personnel, and property</li>
            <li>Managing
              user accounts</li>
            <li>Organizing and running events
              and programs</li>
            <li>Analyzing
              and improving our business</li>
            <li>Managing
              legal issues</li>
          </ul>

          <p>We may also process
            information for the same legitimate interests of our users and business
            partners.</p>

          <h3>Legal Compliance.</h3>

          <p>We may need to use and
            disclose information in certain ways to comply with our legal obligations.</p>

          <h3>Consent.</h3>

          <p>Where required by law, and
            in some other cases where legally permissible, we handle information on the
            basis of consent. Where we handle your information on the basis of consent, you
            have the right to withdraw your consent; in accordance with applicable law.</p>

          <h3>Online Analytics</h3>

          <p>We may use third-party web
            analytics services (such as Google Analytics) on our Services to collect and
            analyze the information discussed above, and to engage in auditing, research,
            or reporting. The information (including your IP address) collected by various
            analytics technologies described in the “Cookies and Other Tracking
            Technologies” section above will be disclosed to or collected directly by these
            service providers, who use the information to evaluate your use of the
            Services, including by noting the third-party website from which you arrive to
            our Site, analyzing usage trends, assisting with fraud prevention, and
            providing certain features to you. To prevent Google Analytics from using your
            information for analytics, you may install the official Google Analytics
            Opt-out Browser Add-on.</p>

          <h3>Your Choices and Data Subject Rights</h3>

          <p>You have various rights
            with respect to the collection and use of your information through the
            Services.&nbsp; Those choices are as follows:</p>

          <h3>Email Unsubscribe</h3>

          <p>You may unsubscribe from
            our marketing emails at any time by clicking on the “unsubscribe” link at the
            bottom of each newsletter or by emailing {{ decodeHex('737570706f72744062697473616e6462797465732e61636164656d79') }} with your
            request.</p>

          <h3>Account Preferences</h3>

          <p>If you have registered for
            an account with us through our Services, you can update your account
            information or adjust your email communications preferences by logging into
            your account and updating your settings.</p>

          <h3>EU Data Subject Rights</h3>

          <p>Individuals in the European
            Economic Area (“EEA”) and other jurisdictions have certain legal rights
            (subject to applicable exceptions and limitations) to obtain confirmation of
            whether we hold certain information about them, to access such information, and
            to obtain its correction or deletion in appropriate circumstances. You may have
            the right to object to our handling of your information, restrict our
            processing of your information, and to withdraw any consent you have provided.
            To exercise these rights, please email us at {{ decodeHex('737570706f72744062697473616e6462797465732e61636164656d79') }} with the
            nature of your request. You also have the right to go directly to the relevant
            supervisory or legal authority, but we encourage you to contact us so that we
            may resolve your concerns directly as best and as promptly as we can.</p>

          <h3>International Transfers</h3>

          <p>As described above in the
            “When We Disclose Your Information” section, we may share your information with
            trusted service providers or business partners in countries other than your
            country of residence in accordance with applicable law. This means that some of
            your information may be processed in countries that may not offer the same
            level of protection as the privacy laws of your jurisdiction. By providing us
            with your information, you acknowledge any such transfer, storage or use.</p>

          <p>If we provide any information
            about you to any third parties information processors located outside of the
            EEA, we will take appropriate measures to ensure such companies protect your
            information adequately in accordance with this Privacy Policy and other data
            protection laws to govern the transfers of such data.</p>

          <p>Your user data is stored
            secured in USA.</p>

          <h3>Security Measures</h3>

          <p>We have implemented
            technical, physical, and organizational security measures to protect against
            the loss, misuse, and/or alteration of your information. These safeguards vary
            based on the sensitivity of the information that we collect and store. However,
            we cannot and do not guarantee that these measures will prevent every
            unauthorized attempt to access, use, or disclose your information since despite
            our efforts, no Internet and/or other electronic transmissions can be
            completely secure.</p>

          <p>Especially the user-id
            (email-address and hashed password) is stored in a protected system of AWS. We
            are not able to read your original password as it is not saved in its original
            letters but hashed.</p>

          <p>Your training information
            is just related to your anonymous user-id without personal information. In case
            you buy a product, this requires to store your email-address in a database with
            restricted read permissions to verify the right to access bought products. </p>

          <h3>Children</h3>

          <p>The Services are intended
            for users over the age of 13 and are not directed at children under the age of
            13. If we become aware that we have collected personal information (as defined
            by the Children’s Online Privacy Protection Act) from children under the age of
            13, or personal data (as defined by the EU GDPR).</p>

          <h3>Data Retention</h3>

          <p>We retain the information
            we collect for as long as necessary to fulfill the purposes set forth in this
            Privacy Policy or as long as we are legally required or permitted to do so.
            Information may persist in copies made for backup and business continuity
            purposes for additional time.</p>

          <p>If you want to remove your
            login credentials from our system, please contact the support from the same
            email address to request it. Any subscriptions or bought products can’t be
            refunded in such a case. Please contact our support or Digistore24 (seller) for a valid refund
            request.</p>

          <h3>Third-Party Links and Services</h3>

          <p>The Services may contain
            links to third-party websites (e.g., social media sites like Facebook and
            Twitter), third-party plug-ins (e.g., the Facebook “like” button and Twitter
            “follow” button), and other services. If you choose to use these sites or
            features, you may disclose your information not just to those third-parties, but
            also to their users and the public more generally depending on how their
            services function. Creative Commons is not responsible for the content or
            privacy practices of such third party websites or services. The collection, use
            and disclosure of your information will be subject to the privacy policies of
            the third party websites or services, and not this Privacy Policy. We encourage
            you to read the privacy statements of each and every site you visit.</p>

          <p>The products you buy are
            sold by a third party provider (Digistore24). They are responsible for protecting
            your data regarding payment. Please contact them directly for any concerns.
            Bits&amp;Bytes does not store your name, postal address or payment information
            except the information that you (identified by email address), bought or
            subscribed to a specific product.</p>

          <h3>Changes to this Privacy Policy</h3>

          <p>We will continue to
            evaluate this Privacy Policy as we update and expand our Services, and we may
            make changes to the Privacy Policy accordingly. We will post any changes here
            and revise the date last updated above. We encourage you to check this page
            periodically for updates to stay informed on how we collect, use and share your
            information. If we make material changes to this Privacy Policy, we will
            provide you with notice as required by law.</p>

          <h3>Questions About this Privacy Policy</h3>

          <p>If you have any questions
            about this Privacy Policy or our privacy practices, you can contact us at:
            {{ decodeHex('737570706f72744062697473616e6462797465732e61636164656d79') }}.</p>





        </div>
      </div>
    </div>
  </section>




</template>

<script>
import Utils from '@/views/Utils.vue'

export default {
  inject: ['sectionCat'],
  mixins: [Utils],

  components: {
  },



  created() {

    window.scrollTo(0,0); // scroll to top


  },



}

</script>