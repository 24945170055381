<script>
export default {
  methods: {
    decodeHex(hexStr) {
      let hexes = hexStr.match(/.{1,2}/g) || [];
      let result = '';
      for (let i = 0; i < hexes.length; i++) {
        result += String.fromCharCode(parseInt(hexes[i], 16));
      }
      return result;
    }
  }
}
</script>