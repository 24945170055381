<template>
  <!-- Start Page Title Area -->
  <div class="page-title-area">
    <div class="container">
      <div class="page-title-content">
        <h2>Legal notice</h2>
        <ul>
          <li>
            <router-link to="/" class="nav-link">
              <i class="bx bx-home"></i>
              Home
            </router-link>
          </li>
          <li class="active">Legal</li>
        </ul>
      </div>
    </div>
  </div>
  <!-- End Page Title Area -->





  <section class="standard-text-page-area">
    <div class="container">
      <div class="terms-conditions">

        <div class="conditions-content">

          <h3>Service-partner and seller</h3>
          <div  class="pb-70">
            <p>Your contractual partner and seller when purchasing our products or using our product subscriptions is Digistore24.com.
              For all payment matters such as payments, direct debits, invoices, etc., as well as for inquiries about personal data that you have provided when ordering products, please contact:</p>
            <a href="https://www.digistore24.com/page/imprint" style="text-decoration: underline">Digistore24 legal info</a>
          </div>


          <h3>Content maintained by</h3>

          <div class="pb-70">
            <p>{{ decodeHex('416e6472656173204865726e697473636865636b') }}</p> <!-- name -->
            <p>{{ decodeHex('45736368656e7374722e203234') }}</p>            <!-- street -->
            <p>{{ decodeHex('38353436342046696e73696e672c204765726d616e79') }}</p> <!-- location -->
          </div>

          <div class="pb-70">
            <p>{{ decodeHex('5461782069643a204445313831323239313232') }}</p> <!-- taxid -->
            <p>{{ decodeHex('436f6e746163743a20737570706f72744062697473616e6462797465732e61636164656d79') }}</p> <!-- email with word contact -->
            <p>{{ decodeHex('50686f6e6520284e4f20535550504f5254293a202b34392032323120333339362d34333735') }}</p> <!-- phone text -->
          </div>

        </div>
      </div>
    </div>
  </section>




</template>

<script>
import Utils from '@/views/Utils.vue'
export default {
  inject: ['sectionCat'],
  mixins: [Utils],

  components: {
  },

  created() {
    window.scrollTo(0,0); // scroll to top
  },




}

</script>