<template>
  <!-- Start Page Title Area -->
  <div class="page-title-area">
    <div class="container">
      <div class="page-title-content">
        <h2>Order Completed</h2>
        <ul>
          <li>
            <router-link to="/" class="nav-link">
              <i class="bx bx-home"></i>
              Home
            </router-link>
          </li>
          <li class="active">Order Completed</li>
        </ul>
      </div>
    </div>
  </div>
  <!-- End Page Title Area -->





  <section class="standard-text-page-area">
    <div class="container">
      <div class="order-completed-area">

        <h2>Thank you!</h2>

        <p><strong>The payment is processed by Digistore24.</strong></p>
        <p v-if="!userSignedIn">Please click the button below to sign-in or register. It is important to use the same email-address you used for the payment.</p>
        <p v-if="!userSignedIn">
          <button @click="navigateToLogin" class="default-btn" v-if="!userSignedIn">
            Sign in for training
          </button>
        </p>
        <p class="order-completed-textline">When you signed-in, navigate to the product section you bought on the <router-link to="/">home page</router-link> and you will see a button to start the training.</p>
        <p>If you dont see it, please wait a few minutes as the payment process is maybe still in progress!</p>

      </div>
    </div>
  </section>


</template>



<script>
import Utils from '@/views/Utils.vue'

export default {
  inject: ['sectionCat'],
  mixins: [Utils],

  components: {
  },

  created() {
    window.scrollTo(0,0); // scroll to top
  },

  methods: {
    navigateToLogin() {
      this.$router.push('/login');
    },
    loadTrustedBadge() {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://www.digistore24.com/trusted-badge/32073/TUKAC6w7pRBWwEX/thankyoupage';
      document.body.appendChild(script);
    },
    removeElementsByClass(className) {
      const elements = document.getElementsByClassName(className);
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }
    }
  },

  mounted() {
    this.loadTrustedBadge(); // Load the trusted badge when the component mounts

    setTimeout(() => {
      this.removeElementsByClass('ds24b-with-text');
    }, 120000); // Remove elements with the class 'ds24b-with-text' after 5 seconds
  },

  computed:{
    userSignedIn(){
      return this.$store.getters.userSignedIn
    }
  }
}
</script>
