<template>
  <!-- Start Page Title Area -->
  <div class="page-title-area">
    <div class="container">
      <div class="page-title-content">
        <h2>Order history</h2>
        <ul>
          <li>
            <router-link to="/" class="nav-link">
              <i class="bx bx-home"></i>
              Home
            </router-link>
          </li>
          <li class="active">Orders</li>
        </ul>
      </div>
    </div>
  </div>
  <!-- End Page Title Area -->



  <section class="">
    <div class="container">
      <div class="orders-area">

          <div v-if="!userSignedIn">You are not signed in!</div>

        <div v-if="userSignedIn">
          <div v-if="orders === null">
            <PulseLoaderBar/>
          </div>
          <div v-if="orders && this.orders.length == 0">
            There are no orders so far.
          </div>
          <div v-if="orders && this.orders.length != 0">

            <div v-for="(order, index) in orders" :key="index" class="one-order-box">


              <div class="order-params-1">

                <div class="order-parameter">
                  <div class="name">Item:</div>
                  <div class="value">{{ order.digistore_params.product_name }}</div>
                </div>

                <div class="order-parameter">
                  <div class="name">Time of purchase:</div>
                  <div class="value">{{ new Date(order.timeOfPurchase + 'Z').toLocaleString(undefined, { timeZoneName: 'short' }) }}</div>
                </div>

                <div class="order-parameter">
                  <div class="name">Expiration of period:</div>
                  <div class="value">{{ new Date(order.timeOfExpiration + 'Z').toLocaleString(undefined, { timeZoneName: 'short' }) }}</div>
                </div>

                <div class="order-parameter" v-if="!order.transferedOrder">
                  <div class="name">Order / Invoice number:</div>
                  <div class="value">{{ order.orderId }}</div>
                </div>


                <div class="order-parameter">
                  <div class="name">Product ID:</div>
                  <div class="value">{{ order.digistore_params.product_id }}</div>
                </div>

                <div class="order-parameter" v-if="!order.transferedOrder">
                  <div class="name">Invoice:</div>
                  <div class="urls">
                    <div><a :href="order.digistore_params.invoice_url" target="_blank">Download invoice</a></div>
                  </div>
                </div>

              </div>


              <div class="order-params-2">

                <div class="order-param-sometext" v-if="order.transferedOrder">
                  <div class="title">Transfered Order</div>
                  <div>This is a transfered order. Means someone else paid for it and you do not see payment details.</div>
                </div>

                <div class="order-parameter" v-if="!order.transferedOrder">
                  <div class="name">Transaction:</div>
                  <div class="value">{{ order.digistore_params.transaction_id }}</div>
                </div>

                <div class="order-parameter" v-if="!order.transferedOrder">
                  <div class="name">Paid:</div>
                  <div class="value">{{ order.digistore_params.transaction_amount }}</div>
                </div>


                <div class="order-parameter" v-if="!order.transferedOrder">
                  <div class="name">Currency:</div>
                  <div class="value">{{ order.digistore_params.transaction_currency }}</div>
                </div>

                <div class="order-parameter" v-if="!order.transferedOrder">
                  <div class="name">Tax:</div>
                  <div class="value">{{ order.digistore_params.transaction_vat_amount }}</div>
                </div>

                <div class="order-parameter" v-if="!order.transferedOrder">
                  <div class="name">Payment method:</div>
                  <div class="value">
                    <div class="value">{{ order.digistore_params.pay_method }}</div>
                  </div>
                </div>

                <div class="order-parameter">
                  <div class="name">Type:</div>
                  <div class="value">
                    <div class="value">{{ order.digistore_params.billing_type }}</div>
                  </div>
                </div>

                <div class="order-parameter">
                  <div class="name">Email used:</div>
                  <div class="value">{{ order.orderEmail }}</div>
                </div>


                <div class="order-parameter" v-if="!order.transferedOrder">
                  <div class="name">Actions:</div>
                  <div class="urls">
                    <div><a :href="order.digistore_params.rebilling_stop_url" target="_blank" v-if="order.digistore_params.billing_type === 'subscription'">Cancel your subscrition</a></div>
                    <div><a :href="order.digistore_params.request_refund_url" target="_blank">Request refund</a></div>
                    <div><a :href="order.digistore_params.order_details_url" target="_blank">Show order details</a></div>
                  </div>
                </div>

              </div>


            </div>

          </div>
        </div>

      </div>
    </div>
  </section>


</template>



<script>
import Utils from '@/views/Utils.vue'
import axios from "axios";
import PulseLoaderBar from "@/views/fragments/PulseLoaderBar.vue";

export default {
  mixins: [Utils],

  components: {
    PulseLoaderBar
  },

  data(){
    return {
      orders: null
    }
  },

  created() {
    window.scrollTo(0,0); // scroll to top

    this.loadOrders();
  },

  methods: {

    loadOrders: function (){
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': this.$store.getters.userAccessToken,
        'Authentication': this.$store.getters.userIdToken, // because it holds the eMail address. The "Authentication" field is nothing API gateway understands, it is an own definition
      }
      const url = process.env.VUE_APP_URL_GET_USRORDERS
      console.log("GET: "+url)
      axios.get(url, { headers: headers }).then(
          res=> {
            this.orders=res.data.payload.orders

            // Sort by transaction_id
            this.orders.sort((a, b) => {
              // Assuming transaction_id is a number and exists in digistore_params
              return b.digistore_params.transaction_id - a.digistore_params.transaction_id;
            });

          }
      ).catch(error => {
        console.log('Axios request error', error);
      })

    }

  },

  computed:{
    userSignedIn(){
      return this.$store.getters.userSignedIn
    }
  }

}

</script>