<template>
  <!-- Start Page Title Area -->
  <div class="page-title-area">
    <div class="container">
      <div class="page-title-content">
        <h2>Newsletter</h2>
        <ul>
          <li>
            <router-link to="/" class="nav-link">
              <i class="bx bx-home"></i>
              Home
            </router-link>
          </li>
          <li class="active">Newsletter</li>
        </ul>
      </div>
    </div>
  </div>
  <!-- End Page Title Area -->





  <section class="standard-text-page-area">
    <div class="container">
      <div class="order-completed-area">

        <h1>Thank you!</h1>

        <p>You've signed up for the newsletter.</p>

      </div>
    </div>
  </section>


</template>



<script>

</script>